// src/CharacterList.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './CharacterList.css';

const CharacterList = () => {
  const [characters, setCharacters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [info, setInfo] = useState({});

  useEffect(() => {
    const fetchCharacters = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`https://rickandmortyapi.com/api/character/?page=${page}&count=20`);
        setCharacters(response.data.results);
        setInfo(response.data.info);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchCharacters();
  }, [page]);

  const handlePrevPage = () => {
    if (info.prev) {
      setPage(page - 1);
    }
  };

  const handleNextPage = () => {
    if (info.next) {
      setPage(page + 1);
    }
  };

  const handlePageChange = (pageNum) => {
    setPage(pageNum);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error fetching characters: {error.message}</p>;

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= info.pages; i++) {
      pageNumbers.push(
        <button
          key={i}
          className={`page-number ${page === i ? 'active' : ''}`}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </button>
      );
    }
    return pageNumbers;
  };

  return (
    <div>
      <h1>Rick and Morty Characters</h1>
      <div className="character-grid">
        {characters.map(character => (
          <div key={character.id} className="character-card">
            <Link to={`/character/${character.id}`}>
              <img src={character.image} alt={character.name} className="character-image" />
              <h2>{character.name}</h2>
            </Link>
          </div>
        ))}
      </div>
      <div className="pagination">
        <button onClick={handlePrevPage} disabled={!info.prev}>
          Previous
        </button>
        {renderPageNumbers()}
        <button onClick={handleNextPage} disabled={!info.next}>
          Next
        </button>
      </div>
    </div>
  );
};

export default CharacterList;
